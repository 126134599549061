import PropTypes from 'prop-types'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo/seo'
import StarredLink from '../components/starred-link/starred-link'
import HeroImg from '../assets/images/hero-landing-design.png'

const query = graphql`
    query {
        contentfulMainPage {
            servicesTitle
            servicesSubtitle
            servicesItems {
                id
                title
                description
                shortDescription
                link
            }
        }
    }
`

const Content = ({ location }) => {
    const currentLocation =
        typeof window !== 'undefined' ? window.location.pathname : ''
    const currentLink = currentLocation.replace(/^\/+|\/+$/g, '')
    const data = useStaticQuery(query)
    const otherServices = data.contentfulMainPage.servicesItems.filter(item => {
        return item.link !== currentLink
    })
    const pageTitle = 'Design and build a product'

    return (
        <Layout>
            <Seo
                title={`${pageTitle} | South Lane`}
                desc="We can help bring your product to life through a simple, transparent, and collaborative process."
                pathname={location.pathname}
            />
            <div className="content-narrow">
                <div className="heading-container heading-container--how-we-can-help">
                    <p>How we can help</p>
                    <h2>{pageTitle}</h2>
                    <div className="tags-list">
                        <div className="tags-list__item">
                            <div className="tag-item">Discovery</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Ideation & Concept</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Wireframing</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Design</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Development</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Testing</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Quality Assurance</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Project Management</div>
                        </div>
                    </div>

                    <div className="common-content-block">
                        <p>
                            Our way of working is centered around making the
                            process of designing and building a product as
                            simple as possible, making sure you have complete
                            visibility into every step of the process, and
                            bringing you in as a key member of our team.
                            Collaboration is a huge part of how we work.
                        </p>
                    </div>
                </div>
            </div>

            <div className="content-hero-image content-hero-image--landing">
                <img src={HeroImg} alt="Design and build a product" />
            </div>

            <div className="content-narrow">
                <div className="content-blocks-list content-blocks-list--services-landing">
                    <div className="common-content-block">
                        <p>
                            If your product needs validation and testing, we
                            start with <strong>discovery</strong>,{' '}
                            <strong>ideation</strong>, <strong>concept</strong>,{' '}
                            <strong>wireframing</strong>, and{' '}
                            <strong>testing</strong>. With all the unknowns
                            clarified and potential solutions tested, we then
                            evaluate <strong>technical requirements</strong> and
                            create an initial <strong>product backlog</strong>{' '}
                            that will be our framework for prioritizing building
                            out your product&apos;s functionality. Our process
                            is agile, which means that <strong>design</strong>,{' '}
                            <strong>development</strong>, and{' '}
                            <strong>quality assurance</strong> happen within
                            2-week iterations so that we can test your product
                            with real users at every step and make improvements
                            as it comes to life.
                        </p>
                        <p>
                            You may want to understand your customer&apos;s
                            problems better so that the potential solutions can
                            be tested and identified before moving into
                            execution. You may have all the data you need and
                            are looking for a team to jump straight into design
                            and development. No matter where you&apos;re at now,
                            we can help bring your product to life through a
                            simple, transparent, and collaborative process.
                        </p>
                    </div>
                </div>

                <div className="content-blocks-list content-blocks-list--services-landing-2">
                    <div className="common-content-block">
                        <h6>More ways we can help</h6>
                    </div>
                </div>

                {otherServices.map(item => (
                    <p key={item.link} className="no-font-size">
                        <StarredLink text={item.title} link={item.link} />
                    </p>
                ))}
            </div>
        </Layout>
    )
}

Content.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
}

export default Content
